import * as React from "react";
import Helmet from "react-helmet";
import { A1, A4, A7, A8 } from "../../components/fonts";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../components/container";

const IndexPage = () => {
  return (
    <>
      <Helmet title="Interview with Kinney Sheth" />
      <Container className="bg-wbSand">
        <div className="col-span-30 md:col-span-8 border-t border-wbTruss mt-24 ml-6 mr-6 md:mr-3 pt-6">
          <A8 className="">
            <span className="text-wbStone">Words:</span>
            <br /> Kinney Sheth <br />
            Diego Segura
          </A8>
          <br />
          <A8 className="">
            <span className="text-wbStone">Photography:</span>
            <br /> Diego Segura
          </A8>
          <br />
          <A8 className="">
            <span className="text-wbStone">Date:</span>
            <br />
            March 1, 2022
          </A8>
          <br />
          <A8 className="">9 minute read</A8>
        </div>
        <div className="col-span-30 md:col-span-22 border-t border-wbTruss mt-24 ml-6 mr-6 md:ml-3 pt-6">
          <A1 className="max-w-5xl">
            <span className="text-wbTruss">Kinney Sheth</span> on her path to{" "}
            <span className="text-wbBlue">Waybridge</span> and insights from her
            career in design
          </A1>
        </div>
        <StaticImage
          className="col-span-30 rounded-md max-w-screen-xl w-auto block mx-auto mt-24"
          src="images/ks-02-22-screen.jpg"
          alt=""
        />

        {/* END OF HEADER */}

        <div className="col-span-30">
          <div className="w-full px-6 md:w-1/2 md:px-0 xl:w-2/6 mx-auto my-32">
            <A4 className="pt-6 pb-32">
              Kinney Sheth is VP of Design at Waybridge, leading innovation in
              all portions of our product. Below is an interview with Diego
              Segura about her career and position at Waybridge.
            </A4>
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Thank you for taking time from sprinting on our product to spend
                some time answering questions. Let&apos;s start from the top.
                Tell me about the genesis of your career in design.
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Of course. <br />
                <br />
                After some self-discovery as a young adult, I was armed with a
                portfolio and a list of companies I wanted to work for in
                Chicago, where I&apos;m from.
                <br />
                <br />
                That list of companies contained, in my view, the best design
                teams in the city. I asked myself, “Who can I learn from? Who is
                doing the most interesting work? Who would I be proud to work
                for?” There were maybe 7 names on that list.
                <br />
                <br />
                Along with that, I spent some time defining what specifically I
                wanted to learn and achieve. It&apos;s a practice I&apos;ve
                continued to do throughout my career.
                <br />
                <br />I applied to each of the design teams, and ended up at
                Wright Auction House. They specialize in 20th century art and
                design, which had me captivated. The team I worked with there
                was fantastic and that&apos;s where I truly learned about
                typography, grid systems, print design, all of that.
              </A7>
            </div>
            <StaticImage
              className="rounded-md my-24"
              src="images/ks-02-22-list.jpg"
              alt=""
            />
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                That sounds like an amazing experience. I&apos;m sure you
                learned all sorts of tiny things, too, that you wouldn&apos;t
                think about from just going to school.
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Yes. I had a very intense boss who micromanaged the way I used
                the design programs, and taught me every shortcut and trick in
                the book.
                <br />
                <br />
                While it sounds like a crazy strategy, it was the most valuable
                experience for me at that point in my career. That manager would
                watch my every move and make me redo it to her standards. It
                trained my eye. I started to understand why it didn&apos;t look
                correct in the first place, and fixed my own mistakes.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                So, where&apos;d you go from there?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                I made the decision so many designers do: to come to New York
                City.
                <br />
                <br />
                I&apos;ll never forget the situation when I first moved here: I
                was a week into a freelance agency gig I&apos;d landed, and
                I&apos;m told: “We lost the business with the client. Everyone
                on your team has been fired, except for you, Kinney. You&apos;re
                our new digital designer.”
                <br />
                <br />
                That&apos;s how I got started creating digital design systems. I
                went upstairs to a different team, and they gave me my first
                assignments.
                <br />
                <br />
                More than graphic design, that team taught me how to think about
                experiences.
                <br />
                <br />
                In fact, I remember now that our UX person broke her hand at one
                point. Our team consisted of a UI designer (me), a UX designer,
                a copywriter, and a product manager. When the UX designer broke
                her hand, I ended up doing both of our jobs, with her talking me
                through her thought processes.
                <br />
                <br />
              </A7>
            </div>
          </div>
          <div className="max-w-screen-lg block mx-auto md:flex md:flex-row">
            <div className="px-6 md:w-1/2">
              <StaticImage
                className="rounded-md"
                src="images/ks-02-22-street.jpg"
                alt=""
              />
            </div>
            <div className="rounded-md relative px-6 my-6 md:my-0 py-32 md:py-0 mx-6 md:mx-0 md:w-1/2 flex items-center bg-wbRust">
              <div className="text-9xl p-12 antialiased hidden md:absolute top-0 left-0 text-wbCopper">
                “”
              </div>
              <A4 className="p-12 text-wbSand text-center">
                “They just told me, ‘You&apos;re our new digital designer.’
                That&apos;s how I made the shift from traditional graphic
                design.”
              </A4>
            </div>
          </div>

          <div className="w-full px-6 md:w-1/2 md:px-0 xl:w-2/6 mx-auto my-32">
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                It&apos;s like hand and brain chess, where two people play chess
                as a team — one person says which piece to move (the brain), the
                other person decides where to move that piece (the hand.)
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                That is exactly how I learned.
                <br />
                <br />
                As time passed, many people from that team moved on into
                different roles, which provided me with a network of colleagues
                in new companies. I heard a lot of, “Hey, you should come join
                me at such-and-such agency.” That provided me with plenty of
                choice on who I wanted to work with, and on what projects.
                <br />
                <br />
                My career at that point became project-focused — I knew it was
                the best way to learn fast. I worked on projects with AKQA,
                Elephant, Red Antler, SYPartners, Virtue (Vice), on projects for
                Verizon, Goldman Sachs, Ralph Lauren, Marc Jacobs, Henry Rose,
                Forty Five Ten — all amazing experiences.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Any projects that stuck out to you from that period of your
                career? Any lessons that stick out to you?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                One in particular:
                <br />
                <br />
                We were working on a project for Goldman Sachs, and it first the
                project was very rocky. Every check-in was turbulent, there were
                misunderstandings between the agency-side design team and the
                client, and it wasn&apos;t going well.
                <br />
                <br />
                We decided to change the way we work with the client, and ended
                up working in an office directly across from the decision makers
                we were working with. Presentations became conversations. It
                built immense trust, made the work go faster, and made us feel
                better while we worked on it.
                <br />
                <br />
                It&apos;s a project I&apos;ll never forget. It&apos;s why we
                design and build so closely with our clients here at Waybridge.
                We&apos;ve got to be on the same wavelength as the people we
                design for.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                That&apos;s smart. And it&apos;s counterintuitive to how so many
                designers prefer to work.
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>I think
                it&apos;s because we&apos;re scared of the judgement. No doubt:
                it is difficult to design for hours and hours on end only to
                show it to a client who tears it down in just a few minutes. But
                I&apos;d much rather be in close dialogue with my client (or in
                the case of Waybridge, the person using our technology) and
                learn fast. At this point in my career, feedback is not
                something I take personally. It&apos;s data collection. And
                it&apos;s my job to do something productive with that data.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Let&apos;s fast-forward a bit. How&apos;d you end up here at
                Waybridge?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                I was at a crossroads in my career, after working on a startup
                briefly and in another tech company, and decided to define the
                qualities I wanted in my dream job. It needed to be in an
                exciting, novel (to me) industry. I needed to work with founders
                with deep experience in that industry. It needed to be a
                promising company, one that I believed in. I wanted to feel a
                strong sense of ownership.
                <br />
                <br />
                It was a long, detailed list. Waybridge was the only company
                that fit the bill. It was the only job I applied to.
              </A7>
            </div>
          </div>

          {/* FULL WIDTH MOMENT */}

          <div className="max-w-screen-lg block mx-auto md:flex md:flex-row">
            <div className="rounded-md relative px-6 my-6 md:my-0 py-32 md:py-0 mx-6 md:mx-0 min-w-screen md:w-1/2 flex items-center bg-wbForest">
              <div className="text-9xl p-12 antialiased hidden md:absolute top-0 left-0 text-wbLeaf">
                “”
              </div>
              <A4 className="p-12 text-wbStem text-center">
                “At this point in my career, feedback is not something I take
                personally. It&apos;s data collection. <br />
                <br />
                And it&apos;s my job to do something productive with that data.”
              </A4>
            </div>
            <div className="px-6 w-full md:w-1/2">
              <StaticImage
                className="rounded-md"
                src="images/ks-02-22-front-door.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="w-full px-6 md:w-1/2 md:px-0 xl:w-2/6 mx-auto my-32">
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                You and me both. I&apos;ve heard that you had a bit of an
                impromptu interview with Waybridge before you joined.
                What&apos;s that story?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                You must be referring to my interview with Andrea. We were on a
                Zoom call to talk about the role, and she asks me where I&apos;m
                at in the city. I told her I was on 22nd Street, where I lived
                at the time. Andrea said, excitedly, “I&apos;m in the office —
                on 22nd Street!”
                <br />
                <br />
                We figured out that we were taking a Zoom call from no more than
                100 yards away from each other in physical space, so I ended up
                coming to the office, meeting Scott and Andrea in-person, and
                continuing my interview. I loved that day. I knew the culture
                was right for me.
                <br />
                <br />
                Everyone here is sharp. At the time of that interview, I could
                tell there was an intuitive empathy for UX, but Waybridge needed
                some help translating that into better designs. The team knew
                where the improvements needed to be made. Most importantly,
                there was an openness to do what it took to make the product
                right.
                <br />
                <br />
                It was such a special round of interviews because of how open
                the conversations were. Andrea and Scott were thinking about me
                as a designer and a job-seeker: “Do you want me to show you the
                platform? Do you want me to tell you about our users?”
                <br />
                <br />
                They showed me where the cards fell, and that helped me see
                where I could make a big impact.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>I love that.
                When you started at Waybridge, there was already a functioning
                version of the product. It was an early iteration, but it
                existed already. How did that impact you and your team&apos;s
                approach to design?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Working from a “first draft” is a great thing. Though there were
                issues at first, all the feedback we were collecting was key in
                shaping our future direction. We didn&apos;t have to start from
                scratch. We had so many data points, sketches, and real-life
                examples to work from.
                <br />
                <br />
                It reminds me of Cunningham&apos;s Law, as said by Ward
                Cunningham, the inventor of modern wikis: “the best way to get
                the right answer on the internet is not to ask a question;
                it&apos;s to post the wrong answer.”
                <br />
                <br />
                We had some wrong answers already on the table, and many right
                answers, too. It&apos;s much easier to have a bunch of thoughts
                on the table and then edit from there. We had all the pieces. We
                just needed to start putting them together. Bridging the gaps,
                if you will.
                <br />
                <br />I made sure to establish that feedback, in all directions,
                was not to be taken personal. We shaped an attitude internally:
                Engineers, product, and design acknowledged that there were
                issues with the product, and that everything was on the table to
                evolve. That&apos;s how good products become great products.
                Nothing is precious. Everything is eligible to be improved.
              </A7>
            </div>
          </div>
        </div>

        <StaticImage
          className="col-span-30 rounded-md max-w-screen-xl w-auto block mx-auto my-24"
          src="images/ks-02-22-couch.jpg"
          alt=""
        />

        <div className="col-span-30">
          <div className="w-full px-6 md:w-1/2 md:px-0 xl:w-2/6 mx-auto my-32">
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Tell me about the current state of the team. What&apos;s it like
                working with the product leaders — Laura, Auloke, Max, Kyle —
                and how does Waybridge build on a daily basis?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Since we&apos;re in such a unique industry — raw materials and
                commodities — there&apos;s a massive barrier to entry for
                designers, engineers, and product managers. You need to know
                about the industry itself, and you need to know how to build a
                state-of-the-art tech product.
                <br />
                <br />
                At the same time, if you have industry expertise, it might be
                difficult to take off that lens and see new approaches. And if
                you come from a tech background, you might be in the deep end in
                many conversations.
                <br />
                <br />
                So, we&apos;ve done something interesting with our hiring: We
                hire people with domain expertise who maybe don&apos;t have as
                much product experience, and then we hire experienced product
                and design people who don&apos;t have enough domain expertise.
                <br />
                <br />
                We&apos;re all learning from each other. Constantly.
                <br />
                <br />
                Being a domain expert in commodities is not more difficult than
                being a domain expert in product management or design. Both are
                challenging. But there are no people on the job market who have
                built what Waybridge is building before. It&apos;s never been
                done. This is our way of creating a new, special blend of
                product manager and designer.
                <br />
                <br />
                It also builds a healthy approach between roles. We don&apos;t
                describe solutions in terms of absolutes. You can&apos;t have
                full confidence in any one answer — at least not at this point.
                We ask each other loads of questions. We test constantly with
                our users. Everything is in flux.
              </A7>
            </div>
          </div>
        </div>

        <StaticImage
          className="col-span-30 rounded-md max-w-screen-xl w-auto block mx-auto my-24"
          src="images/ks-02-22-desk.jpg"
          alt=""
        />

        <div className="col-span-30">
          <div className="w-full px-6 md:w-1/2 md:px-0 xl:w-2/6 mx-auto my-32">
            <div className="grid space-y-8">
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Tell me about our interactions with our clients. Do our teams
                maintain a dialogue with customers?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Yes — all the time.
                <br />
                <br />
                Our design team is always showing a new feature, mockup, or
                prototype to users. We learn about the scenarios our users are
                having trouble with, and then walk them through solutions for
                those problems.
                <br />
                <br />
                There&apos;s an interesting challenge there, too: Waybridge, as
                a technology, serves many different roles.
                <br />
                <br />
                There are people who interface with Waybridge at an executive
                level, and people who use it for data entry and record keeping.
                People who sit in a very different seat at a client both have to
                log in to Waybridge every day. We&apos;re constantly reminding
                ourselves of these subtle differences in personalities and
                attitudes toward software. It pushes us to design much more
                inclusively.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Thank you for spending so much time speaking about design and
                walking me through your career. I only have one more question —
                what&apos;s the future look like? Any goals of yours that we
                should record for history&apos;s sake?
              </A7>
              <A7 className="pl-16 relative">
                <span className="top-0 left-0 absolute">K</span>
                Well, there&apos;s plenty of work to be done on our product that
                I can&apos;t quite mention in a public interview, but there is a
                high-level goal of mine that I love to share.
                <br />
                <br />
                I&apos;ve always held this strong belief that design, at its
                core, is a way of thinking. It&apos;s not about typefaces and
                colors and button components in Figma. It&apos;s a skillset that
                can change any organization: from product to finance to sales.
                <br />
                <br />
                As a company, the main reason we hire designers is to build our
                product. But with designers comes a culture: one where we humble
                ourselves to the possibility that we&apos;re wrong. Where we
                approach everything as an assumption to be questioned — and
                hopefully, improved upon. We should intentionally design the way
                we lead and mentor the young people in our company. We should
                design conversations around trust, safety, and inclusiveness in
                our company.
                <br />
                <br />
                If design has taught me anything, it&apos;s that everything can
                be made better.
                <br />
                <br />I hope to infuse that into the culture of Waybridge at
                every turn.
              </A7>
              <A7 className="pl-16 relative text-wbStone50">
                <span className="top-0 left-0 absolute">D</span>
                Thank you again, Kinney.
              </A7>
              <A7 className="pl-16 relative mb-24">
                <span className="top-0 left-0 absolute">K</span>
                Thank you.
              </A7>
            </div>
            <StaticImage
              className="my-24 rounded-md"
              src="images/ks-02-22-portrait.jpg"
              alt=""
            />
            <A8 className="max-w-md">
              Kinney Sheth is the VP of Design at{" "}
              <span className="text-wbBlue">Waybridge</span>, working on
              transformative design and technology for the raw materials supply
              chain. You can connect with Kinney on{" "}
              <a
                href="https://www.linkedin.com/in/kinneysheth/"
                className="underline"
              >
                LinkedIn
              </a>
              .
              <br />
              <br />
              If you&apos;d like to join the design team at Waybridge,{" "}
              <a href="/open-roles" className="underline">
                click here
              </a>{" "}
              to see our open roles.
              <br />
              <br />
              <span className="text-wbStone">
                Photos by Diego Segura.
                <br />
                <br />
                Shot on a Leica M6 on Portra 400.
              </span>
            </A8>
          </div>
        </div>
      </Container>
    </>
  );
};
export default IndexPage;
